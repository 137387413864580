<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="手术室叫号"/>
        <div style="text-align: center;padding: 30px 20px;">
            <el-radio-group v-model="radio1">
                <el-radio-button label="门诊手术室1"></el-radio-button>
                <el-radio-button label="门诊手术室2"></el-radio-button>
                <el-radio-button label="门诊手术室3"></el-radio-button>
                <el-radio-button label="住院2部手术室1"></el-radio-button>
                <el-radio-button label="住院2部手术室2"></el-radio-button>
                <el-radio-button label="住院2部手术室3"></el-radio-button>
            </el-radio-group>
        </div>
        <div class="transfer-box">
            <el-transfer
                style="text-align: left; display: inline-block"
                v-model="value"
                filterable
                :left-default-checked="[2, 3]"
                :right-default-checked="[1]"
                :render-content="renderFunc"
                :titles="['待手术', this.radio1]"
                :button-texts="['等待中', '排手术']"
                :format="{
        noChecked: '${total}',
        hasChecked: '${checked}/${total}'
      }"
                @change="handleChange"
                :data="data">
                <el-button class="transfer-footer" slot="left-footer" size="small">操作</el-button>
                <el-button class="transfer-footer" slot="right-footer" size="small">操作</el-button>
            </el-transfer>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            // eslint-disable-next-line no-unused-vars
            const generateData = _ => {
                const data = [];
                for (let i = 1; i <= 25; i++) {
                    data.push({
                        key: i,
                        label: `08:00-09:00 李医生 患者1 皮肤肿物切除术${ i }`,
                        disabled: i % 4 === 0
                    });
                }
                return data;
            };
            return {
                radio1: '门诊手术室1',
                data: generateData(),
                value: [1],
                value4: [1],
                renderFunc(h, option) {
                    return <span>{ option.key } - { option.label }</span>;
                }
            };
        },

        methods: {
            handleChange(value, direction, movedKeys) {
                console.log(value, direction, movedKeys);
            }
        }
    };
</script>

<style scoped>
    .transfer-box {
        text-align: center;
    }
    .transfer-box >>> .el-transfer-panel {
        width:400px;
    }
</style>
